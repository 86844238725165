<template>
    <span
        class="badge badge-pill d-inline-flex align-items-center"
        :class="[variantClass, badgeType]"
    >
        <span
            v-if="value && value.length > 20"
            v-b-tooltip.hover="value"
            class="text"
        >
            {{ value }}
        </span>
        <span v-else>{{ value }}</span>
        <i
            v-if="deletable"
            class="fd-close ml-1 cursor-pointer"
            @click.stop="$emit('delete')"
        />
    </span>
</template>

<script>
export default {
    name: 'BaseBadge',

    props: {
        value: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: 'secondary',
        },
        small: Boolean,
        deletable: Boolean,
    },

    emits: ['delete'],

    computed: {
        variantClass() {
            switch (this.variant) {
                case 'warning': {
                    return 'badge-warning';
                }
                case 'plain': {
                    return 'border-color-gray-600';
                }
                case 'success':
                    return 'bg-success';
                case 'done':
                    return 'bg-success-light';
                case 'not done':
                    return 'bg-red-light';
                case 'info':
                    return 'bg-info';
                case 'secondary':
                default: {
                    return 'badge-secondary';
                }
            }
        },
        badgeType() {
            if (this.small) {
                return 'badge-small';
            }
            return null;
        },
    },
};
</script>

<style lang="scss">
.badge-pill {
    border-radius: 14px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;

    .text {
        display: inline-block;
        max-width: 10rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.border-color-gray-600 {
    border-color: $gray-600 !important;
}
</style>
